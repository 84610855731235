import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Polygon,
  InfoWindow,
  MarkerClusterer
} from '@react-google-maps/api'

import './map.sass'

import Spinner from '../../../../components/Spinner/Spinner'

import car from './img/car.svg'
import icon from "./img/marker.svg"

import WithService from '../../../../services/hoc'
import { useSelector } from 'react-redux'


const Map = (props) => {
  const { t } = useTranslation()

  const green_item = React.useRef()
  const yellow_item = React.useRef()
  const red_item = React.useRef()

  const mapRef = React.useRef()
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map
  }, [])

  const [mapKey] = useState(process.env.REACT_APP_GOOGLE_API_KEY)
  const [mapCenter] = useState({ lat: +process.env.REACT_APP_GMAP_DEFAULT_LAT, lng: +process.env.REACT_APP_GMAP_DEFAULT_LNG })
  const [mapType, setMapType] = useState(false)
  const [selected, setSelected] = useState(null)

  const mapCounter = useSelector((state) => state.mapCounter)
  const mapMarkers = useSelector((state) => state.mapMarkers)
  const mapPolygons = useSelector((state) => state.mapPolygons)

  const leftTitle = useRef()
  const rightTitle = useRef()
  const mapBlock = useRef()
  const checkbox = useRef()

  const onCloseBlock = (e) => {
    e.target.classList.toggle('active')
    mapBlock.current.classList.toggle('active')
  }

  const checkedTrue = (titleClick = false) => {
    mapBlock.current.children[0].style.display = 'none'
    mapBlock.current.children[1].style.display = 'block'

    leftTitle.current.classList.remove('active')
    rightTitle.current.classList.add('active')

    if (titleClick) checkbox.current.checked = true

    setMapType(true)
    setSelected(null)
  }

  const checkedTrueClicked = () => checkedTrue(true)

  const checkedFalse = (titleClick = false) => {
    mapBlock.current.children[0].style.display = 'block'
    mapBlock.current.children[1].style.display = 'none'

    leftTitle.current.classList.add('active')
    rightTitle.current.classList.remove('active')

    if (titleClick) checkbox.current.checked = false

    setMapType(false)
    setSelected(null)
  }

  const checkedFalseClicked = () => checkedFalse(true)

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: mapKey,
  })

  const showInfo = (e) => {
    if (e.target.className === 'map-block__item__text' || e.target.className === 'map-block__item__text-link') return

    if (e.currentTarget.classList.contains('green')) {
      yellow_item.current.classList.remove('active')
      yellow_item.current.children[1].style.maxHeight = null
      red_item.current.classList.remove('active')
      red_item.current.children[1].style.maxHeight = null
    } else if (e.currentTarget.classList.contains('yellow')) {
      green_item.current.classList.remove('active')
      green_item.current.children[1].style.maxHeight = null
      red_item.current.classList.remove('active')
      red_item.current.children[1].style.maxHeight = null
    } else if (e.currentTarget.classList.contains('red')) {
      green_item.current.classList.remove('active')
      green_item.current.children[1].style.maxHeight = null
      yellow_item.current.classList.remove('active')
      yellow_item.current.children[1].style.maxHeight = null
    }

    if (e.currentTarget.classList.contains('active'))
      e.currentTarget.classList.remove('active')
    else
      e.currentTarget.classList.add('active')

    const panel = e.currentTarget.children[1]
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px"
    }
  }


  if (loadError) return <div></div>
  if (!isLoaded) return <section className="map"><Spinner /></section>

  return (
    <section className="map">

      <div className="map-container">
        <div className="container">

          <div className="map-block">
            <div className="map-block__header">
              <span
                className="map-checkbox__title active"
                ref={leftTitle}
                onClick={checkedFalseClicked}
              >
                {t("main.car")}
              </span>
              <label className="map-block__switch">
                <input
                  className="map-block__input"
                  type="checkbox"
                  ref={checkbox}
                  onClick={(e) => { e.target.checked ? checkedTrue() : checkedFalse() }}
                />
                <span className="map-block__slider"></span>
              </label>
              <span
                className="map-checkbox__title"
                ref={rightTitle}
                onClick={checkedTrueClicked}
              >
                {t("main.zones")}
              </span>

              <div className="map-arrow" onClick={onCloseBlock}>
                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7L13 1" stroke="#E01254" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>

            <div className="map-block__body active" ref={mapBlock}>

              <div className="map-block__content">
                <div className="map-block__title">{t("main.map_header_car")}</div>
                <div className="map-block__subtitle">
                  {t("main.map_body_car")}
                </div>
                <div className="map-block__info">
                  <span className="map-block__info-title">{t("main.map_body_car_available")}</span>
                  <div className="map-block__info-block">

                    <span className="map-block__info-count">{mapCounter ? mapCounter : '--'}</span>
                    <div className="map-block__info-img">
                      <img src={car} alt="Pieejamās automašīnas" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="map-block__content" style={{ display: 'none' }}>
                <div className="map-block__title">{t("main.map_header_zones")}</div>
                <dl className="map-block__items">
                  <div
                    className="map-block__item green active"
                    ref={green_item}
                    onClick={showInfo}
                  >
                    <dt>
                      <button className="map-block__item-btn">
                        <div className="map-block__item-title">
                          <div className="map-block__item-color green" />
                          <span> {t('main.map_zones.green_title')} </span>
                        </div>
                        <svg className="map-block__item__arrow" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L7 7L13 1" stroke="#E01254" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </button>
                    </dt>
                    <dd style={{ maxHeight: `1000px` }}>
                      <div className="map-block__item__text">
                        {t('main.map_zones.green_subtitle')}
                        <a
                          href={`/docs/parkinglist/parkinglist.pdf`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="map-block__item__text-link"
                        >{t('main.map_zones.green_link')}</a>
                      </div>
                    </dd>
                  </div>

                  <div
                    className="map-block__item yellow"
                    ref={yellow_item}
                    onClick={showInfo}
                  >
                    <dt>
                      <button className="map-block__item-btn">
                        <div className="map-block__item-title">
                          <div className="map-block__item-color yellow" />
                          <span> {t('main.map_zones.yellow_title')} </span>
                        </div>
                        <svg className="map-block__item__arrow" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L7 7L13 1" stroke="#E01254" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </button>
                    </dt>
                    <dd>
                      <div className="map-block__item__text">
                        {t('main.map_zones.yellow_subtitle')}
                      </div>
                    </dd>
                  </div>

                  <div
                    className="map-block__item red"
                    ref={red_item}
                    onClick={showInfo}
                  >
                    <dt>
                      <button className="map-block__item-btn">
                        <div className="map-block__item-title">
                          <div className="map-block__item-color red" />
                          <span> {t('main.map_zones.red_title')} </span>
                        </div>
                        <svg className="map-block__item__arrow" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L7 7L13 1" stroke="#E01254" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </button>
                    </dt>
                    <dd>
                      <div className="map-block__item__text">
                        {t('main.map_zones.red_subtitle')}
                      </div>
                    </dd>
                  </div>

                </dl>
              </div>

            </div>
          </div>

        </div>
      </div>

      <div className="map-content">
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          zoom={12}
          center={mapCenter}
          options={{
            disableDefaultUI: true,
            zoomControl: true,
            styles: props.mapStyles
          }}
          onLoad={onMapLoad}
        >
          {mapType ? (
            <>{mapPolygons.map((polygon, i) => (
              <Polygon
                path={polygon.position}
                key={`${polygon.position[0].lat}_${polygon.position[0].lng}_${i}`}
                options={polygon.options}
                onClick={(e) => {
                  setSelected({
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                    desc: polygon.description
                  })
                }}
              />
            ))}</>
          ) : (
            <MarkerClusterer
              options={{
                imagePath: '/img/map/cluster',
                imageSizes: [76],
                clusterClass: 'map-cluster'
              }}
              averageCenter
            >
              {(clusterer) =>
                mapMarkers.map((marker) => (
                  <Marker
                    key={marker.device.latLng}
                    position={{ lat: marker.device.latitude, lng: marker.device.longitude }}
                    icon={{
                      url: icon,
                      scaledSize: new window.google.maps.Size(51, 67)
                    }}
                    clusterer={clusterer}
                  />
                ))
              }
            </MarkerClusterer>
          )}

          {/* {selected && ( */}
          {null && (
            <InfoWindow
              position={{ lat: selected.lat, lng: selected.lng }}
              onCloseClick={() => setSelected(null)}
              defaultOptions={false}
            >
              <span>{selected.desc}</span>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
    </section>
  )
}

Map.defaultProps = {
  mapStyles: [
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        { "color": "#e9e9e9" },
        { "lightness": 17 }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [
        { "color": "#f5f5f5" },
        { "lightness": 20 }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
        { "color": "#ffffff" },
        { "lightness": 17 }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        { "color": "#ffffff" },
        { "lightness": 29 },
        { "weight": 0.2 }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        { "color": "#ffffff" },
        { "lightness": 18 }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [
        { "color": "#ffffff" },
        { "lightness": 16 }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        { "color": "#f5f5f5" },
        { "lightness": 21 }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        { "color": "#dedede" },
        { "lightness": 21 }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        { "visibility": "on" },
        { "color": "#ffffff" },
        { "lightness": 16 }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        { "saturation": 36 },
        { "color": "#333333" },
        { "lightness": 40 }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        { "visibility": "off" }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "geometry",
      "stylers": [
        { "color": "#f2f2f2" },
        { "lightness": 19 }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.fill",
      "stylers": [
        { "color": "#fefefe" },
        { "lightness": 20 }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [
        { "color": "#fefefe" },
        { "lightness": 17 },
        { "weight": 1.2 }
      ]
    }
  ]
}

export default WithService()(Map)